<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card
          :title="$t('restore New Plate')"
        >
          <b-row>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number1"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number1 ?false:null"
                  :class="errors.number1 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number1"
                  class="text-danger"
                >{{ errors.number1[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number2"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number2 ?false:null"
                  :class="errors.number2 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number2"
                  class="text-danger"
                >{{ errors.number2[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number3"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number3 ?false:null"
                  :class="errors.number3 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number3"
                  class="text-danger"
                >{{ errors.number3[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number4"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number4 ?false:null"
                  :class="errors.number4 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number4"
                  class="text-danger"
                >{{ errors.number4[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number5"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number5 ?false:null"
                  :class="errors.number5 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number5"
                  class="text-danger"
                >{{ errors.number5[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number6"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number6 ?false:null"
                  :class="errors.number6 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number6"
                  class="text-danger"
                >{{ errors.number6[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number7"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number7 ?false:null"
                  :class="errors.number7 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number7"
                  class="text-danger"
                >{{ errors.number7[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number8"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number8 ?false:null"
                  :class="errors.number8 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number8"
                  class="text-danger"
                >{{ errors.number8[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number9"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number9 ?false:null"
                  :class="errors.number9 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number9"
                  class="text-danger"
                >{{ errors.number9[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number10"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number10 ?false:null"
                  :class="errors.number10 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number10"
                  class="text-danger"
                >{{ errors.number10[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number11"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number11 ?false:null"
                  :class="errors.number11 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number11"
                  class="text-danger"
                >{{ errors.number11[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number12"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number12 ?false:null"
                  :class="errors.number12 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number12"
                  class="text-danger"
                >{{ errors.number12[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number13"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number13 ?false:null"
                  :class="errors.number13 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number13"
                  class="text-danger"
                >{{ errors.number13[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number14"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number14 ?false:null"
                  :class="errors.number14 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number14"
                  class="text-danger"
                >{{ errors.number14[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number15"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number15 ?false:null"
                  :class="errors.number15 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number15"
                  class="text-danger"
                >{{ errors.number15[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number16"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number16 ?false:null"
                  :class="errors.number16 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number16"
                  class="text-danger"
                >{{ errors.number16[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number17"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number17 ?false:null"
                  :class="errors.number17 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number17"
                  class="text-danger"
                >{{ errors.number17[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number18"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number18 ?false:null"
                  :class="errors.number18 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number18"
                  class="text-danger"
                >{{ errors.number18[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number19"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number19 ?false:null"
                  :class="errors.number19 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number19"
                  class="text-danger"
                >{{ errors.number19[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number20"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number20 ?false:null"
                  :class="errors.number20 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number20"
                  class="text-danger"
                >{{ errors.number20[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number21"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number21 ?false:null"
                  :class="errors.number21 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number21"
                  class="text-danger"
                >{{ errors.number21[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number22"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number22 ?false:null"
                  :class="errors.number22 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number22"
                  class="text-danger"
                >{{ errors.number22[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number23"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number23 ?false:null"
                  :class="errors.number23 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number23"
                  class="text-danger"
                >{{ errors.number23[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.number24"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('NumberD')"
                  :state="errors.number24 ?false:null"
                  :class="errors.number24 ?'is-invalid':null"
                />
                <small
                  v-if="errors.number24"
                  class="text-danger"
                >{{ errors.number24[0] }}</small>
                <label>{{ $t('NumberD' ) }}</label>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>

          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="create"
                  >
                    {{ $t('Create') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="data={},errors=[]"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        multi: 'false',
        available: 'false',
        number: '',
        count: '',
      },
      errors: [],
      fileErrors: '',

    }
  },
  computed: {},
  watch: {
    // eslint-disable-next-line func-names
    'data.multi': function (val) {
      if (val === true) {
        this.data.available = false
      } else {
        this.data.count = ''
      }
    },
  },
  created() {
  },
  methods: {
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.errors = []
      this.$store.dispatch('plate/UpdateMulti', this.data).then(() => {
        this.data = {
          multi: 'false',
          available: 'false',
          number: '',
          count: '',
        }
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
