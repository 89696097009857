var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-card',{attrs:{"title":_vm.$t('restore New Plate')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number1 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number1 ?false:null},model:{value:(_vm.data.number1),callback:function ($$v) {_vm.$set(_vm.data, "number1", $$v)},expression:"data.number1"}}),(_vm.errors.number1)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number1[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number2 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number2 ?false:null},model:{value:(_vm.data.number2),callback:function ($$v) {_vm.$set(_vm.data, "number2", $$v)},expression:"data.number2"}}),(_vm.errors.number2)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number2[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number3 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number3 ?false:null},model:{value:(_vm.data.number3),callback:function ($$v) {_vm.$set(_vm.data, "number3", $$v)},expression:"data.number3"}}),(_vm.errors.number3)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number3[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number4 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number4 ?false:null},model:{value:(_vm.data.number4),callback:function ($$v) {_vm.$set(_vm.data, "number4", $$v)},expression:"data.number4"}}),(_vm.errors.number4)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number4[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number5 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number5 ?false:null},model:{value:(_vm.data.number5),callback:function ($$v) {_vm.$set(_vm.data, "number5", $$v)},expression:"data.number5"}}),(_vm.errors.number5)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number5[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number6 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number6 ?false:null},model:{value:(_vm.data.number6),callback:function ($$v) {_vm.$set(_vm.data, "number6", $$v)},expression:"data.number6"}}),(_vm.errors.number6)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number6[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number7 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number7 ?false:null},model:{value:(_vm.data.number7),callback:function ($$v) {_vm.$set(_vm.data, "number7", $$v)},expression:"data.number7"}}),(_vm.errors.number7)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number7[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number8 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number8 ?false:null},model:{value:(_vm.data.number8),callback:function ($$v) {_vm.$set(_vm.data, "number8", $$v)},expression:"data.number8"}}),(_vm.errors.number8)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number8[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number9 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number9 ?false:null},model:{value:(_vm.data.number9),callback:function ($$v) {_vm.$set(_vm.data, "number9", $$v)},expression:"data.number9"}}),(_vm.errors.number9)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number9[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number10 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number10 ?false:null},model:{value:(_vm.data.number10),callback:function ($$v) {_vm.$set(_vm.data, "number10", $$v)},expression:"data.number10"}}),(_vm.errors.number10)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number10[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number11 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number11 ?false:null},model:{value:(_vm.data.number11),callback:function ($$v) {_vm.$set(_vm.data, "number11", $$v)},expression:"data.number11"}}),(_vm.errors.number11)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number11[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number12 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number12 ?false:null},model:{value:(_vm.data.number12),callback:function ($$v) {_vm.$set(_vm.data, "number12", $$v)},expression:"data.number12"}}),(_vm.errors.number12)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number12[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number13 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number13 ?false:null},model:{value:(_vm.data.number13),callback:function ($$v) {_vm.$set(_vm.data, "number13", $$v)},expression:"data.number13"}}),(_vm.errors.number13)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number13[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number14 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number14 ?false:null},model:{value:(_vm.data.number14),callback:function ($$v) {_vm.$set(_vm.data, "number14", $$v)},expression:"data.number14"}}),(_vm.errors.number14)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number14[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number15 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number15 ?false:null},model:{value:(_vm.data.number15),callback:function ($$v) {_vm.$set(_vm.data, "number15", $$v)},expression:"data.number15"}}),(_vm.errors.number15)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number15[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number16 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number16 ?false:null},model:{value:(_vm.data.number16),callback:function ($$v) {_vm.$set(_vm.data, "number16", $$v)},expression:"data.number16"}}),(_vm.errors.number16)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number16[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number17 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number17 ?false:null},model:{value:(_vm.data.number17),callback:function ($$v) {_vm.$set(_vm.data, "number17", $$v)},expression:"data.number17"}}),(_vm.errors.number17)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number17[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number18 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number18 ?false:null},model:{value:(_vm.data.number18),callback:function ($$v) {_vm.$set(_vm.data, "number18", $$v)},expression:"data.number18"}}),(_vm.errors.number18)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number18[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number19 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number19 ?false:null},model:{value:(_vm.data.number19),callback:function ($$v) {_vm.$set(_vm.data, "number19", $$v)},expression:"data.number19"}}),(_vm.errors.number19)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number19[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number20 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number20 ?false:null},model:{value:(_vm.data.number20),callback:function ($$v) {_vm.$set(_vm.data, "number20", $$v)},expression:"data.number20"}}),(_vm.errors.number20)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number20[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number21 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number21 ?false:null},model:{value:(_vm.data.number21),callback:function ($$v) {_vm.$set(_vm.data, "number21", $$v)},expression:"data.number21"}}),(_vm.errors.number21)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number21[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number22 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number22 ?false:null},model:{value:(_vm.data.number22),callback:function ($$v) {_vm.$set(_vm.data, "number22", $$v)},expression:"data.number22"}}),(_vm.errors.number22)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number22[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number23 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number23 ?false:null},model:{value:(_vm.data.number23),callback:function ($$v) {_vm.$set(_vm.data, "number23", $$v)},expression:"data.number23"}}),(_vm.errors.number23)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number23[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.number24 ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('NumberD'),"state":_vm.errors.number24 ?false:null},model:{value:(_vm.data.number24),callback:function ($$v) {_vm.$set(_vm.data, "number24", $$v)},expression:"data.number24"}}),(_vm.errors.number24)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.number24[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('NumberD' )))])],1)])],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"w-100 mb-1",attrs:{"variant":"gradient-success"},on:{"click":_vm.create}},[_vm._v(" "+_vm._s(_vm.$t('Create'))+" ")]),_c('b-button',{staticClass:"w-100",attrs:{"variant":"gradient-danger"},on:{"click":function($event){_vm.data={},_vm.errors=[]}}},[_vm._v(" "+_vm._s(_vm.$t('Reset Form'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }